import * as React from 'react'
import { isLoggedIn } from '@commonstock/common/src/auth'
import { cx } from '@linaria/core'
import { HTMLDivProps } from 'src/utils/types'

const DYNAMIC_CLASSNAME = 'dynamic'

if (isLoggedIn()) {
  let dynamicEls = document.getElementsByClassName(DYNAMIC_CLASSNAME)
  Array.prototype.forEach.call(dynamicEls, el => el.remove)
}

// this component will remove itself from the dom when rendering client side for logged in users, that way we avoid SSR issues
// for design considerations see https://www.notion.so/commonstock/Server-Side-Rendering-f60751986efa4059a2077235673221ba
export default function DynamicHydrateDiv({
  children,
  className,
  ...restProps
}: { children?: React.ReactNode } & HTMLDivProps) {
  return (
    <div className={cx(DYNAMIC_CLASSNAME, className)} {...restProps}>
      {children}
    </div>
  )
}
